/* Container for each media item */
.media-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; /* To position the cross button */
    transition: all 0.3s ease;
  }
  
  .DateInput_input{
    font-size: 0.875rem;
    padding: unset;
  }

  .DateInput{
    width: 90px;
  }

  .DateRangePickerInput_arrow{
    margin-right: 10px;
  }
  
  /* Hover effect on media item container */
  .media-item:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Display media name */
  .media-name {
    flex: 1;
    font-size: 18px;
    /* font-weight: bold; */
    color: #333;
    margin-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  /* Cross button style */
  .cross-button {
    position: relative;
    top: 0px;
    right: 0px;
    background: none;
    border: none;
    font-size: 15px;
    color: #ff4d4f; /* Red color for the cross */
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  /* Hover effect on cross button */
  .cross-button:hover {
    color: #d9534f; /* Darker red on hover */
  }
  
  /* Download button style */
  .download-button {
    background-color: #007bff; /* Blue color */
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    text-transform: uppercase;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  /* Hover effect for download button */
  .download-button:hover {
    background-color: #0056b3; /* Darker blue */
    transform: scale(1.05);
  }
  
  /* Focus effect for accessibility */
  .download-button:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.6);
  }
  
  /* Image preview style */
  .media-preview {
    width: 100px;
    height: auto;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  /* Video preview style */
  .media-preview-video {
    width: 150px;
    height: 100px;
    margin-right: 20px;
    border-radius: 8px;
  }
  
  .form-select:disabled{
    color: light-dark(graytext, rgb(170, 170, 170));
    background-color: #eee !important;
    border-color: rgba(118, 118, 118, 0.3);
  }